// tslint:disable
/**
 * Codeanywhere Collaboration REST API
 * Codeanywhere Collaboration REST API
 *
 * The version of the OpenAPI document: 0.6.53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ContainerState
 */
export interface ContainerState {
    /**
     * Container State
     * @type {number}
     * @memberof ContainerState
     */
    state: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface Share
 */
export interface Share {
    /**
     * Share id
     * @type {string}
     * @memberof Share
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Share
     */
    admin: User;
    /**
     * Container id
     * @type {number}
     * @memberof Share
     */
    containerId: number;
    /**
     * Container name
     * @type {string}
     * @memberof Share
     */
    containerName?: string;
    /**
     * Email list of invited collaborators
     * @type {Array<ShareInvite>}
     * @memberof Share
     */
    invites: Array<ShareInvite>;
    /**
     * User id list of collaborators
     * @type {Array<ShareUser>}
     * @memberof Share
     */
    users: Array<ShareUser>;
    /**
     * Link
     * @type {string}
     * @memberof Share
     */
    link: string;
    /**
     * Date of plan expiration
     * @type {string}
     * @memberof Share
     */
    expires?: string;
}
/**
 * 
 * @export
 * @interface ShareCreation
 */
export interface ShareCreation {
    /**
     * Container Id
     * @type {number}
     * @memberof ShareCreation
     */
    containerId: number;
    /**
     * Date of share expiration
     * @type {string}
     * @memberof ShareCreation
     */
    expires?: string;
}
/**
 * 
 * @export
 * @interface ShareInvite
 */
export interface ShareInvite {
    /**
     * Email of invited user
     * @type {string}
     * @memberof ShareInvite
     */
    email: string;
    /**
     * Datetime of notify user action
     * @type {string}
     * @memberof ShareInvite
     */
    sent?: string;
}
/**
 * 
 * @export
 * @interface ShareUser
 */
export interface ShareUser {
    /**
     * Collaborator user id
     * @type {number}
     * @memberof ShareUser
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof ShareUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ShareUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ShareUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareUser
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareUser
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof ShareUser
     */
    imageurl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShareUser
     */
    isProtected?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * User id
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    imageurl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferInviteeToRegisteredUsers: async (body?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/transfer-invitees`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferInviteeToRegisteredUsers(body?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AdminApiAxiosParamCreator(configuration).transferInviteeToRegisteredUsers(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {InlineObject} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferInviteeToRegisteredUsers(body?: InlineObject, options?: any): AxiosPromise<object> {
            return AdminApiFp(configuration).transferInviteeToRegisteredUsers(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public transferInviteeToRegisteredUsers(body?: InlineObject, options?: any) {
        return AdminApiFp(this.configuration).transferInviteeToRegisteredUsers(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ShareApi - axios parameter creator
 * @export
 */
export const ShareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProtectedUser: async (id: string, body?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addProtectedUser.');
            }
            const localVarPath = `/shares/{id}/add-protected-user`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShareCreation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShare: async (body: ShareCreation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createShare.');
            }
            const localVarPath = `/shares`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShare: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteShare.');
            }
            const localVarPath = `/shares/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShare: async (id: string, id2?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getShare.');
            }
            const localVarPath = `/shares/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id2 !== undefined) {
                localVarQueryParameter['id'] = id2;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareByContainerId: async (containerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerId' is not null or undefined
            if (containerId === null || containerId === undefined) {
                throw new RequiredError('containerId','Required parameter containerId was null or undefined when calling getShareByContainerId.');
            }
            const localVarPath = `/shares/container/{containerId}`
                .replace(`{${"containerId"}}`, encodeURIComponent(String(containerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareContainerState: async (slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling getShareContainerState.');
            }
            const localVarPath = `/shares/share-container-state/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShares: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shares`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToShare: async (id: string, body?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling inviteUserToShare.');
            }
            const localVarPath = `/shares/{id}/invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveShare: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling leaveShare.');
            }
            const localVarPath = `/shares/{id}/leave`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromShare: async (id: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeUserFromShare.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling removeUserFromShare.');
            }
            const localVarPath = `/shares/{id}/invite/{email}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startShareContainer: async (slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling startShareContainer.');
            }
            const localVarPath = `/shares/start-share-container/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShareApi - functional programming interface
 * @export
 */
export const ShareApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProtectedUser(id: string, body?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).addProtectedUser(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ShareCreation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShare(body: ShareCreation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Share>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).createShare(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShare(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).deleteShare(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShare(id: string, id2?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Share>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).getShare(id, id2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShareByContainerId(containerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Share>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).getShareByContainerId(containerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShareContainerState(slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerState>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).getShareContainerState(slug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShares(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Share>>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).getShares(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUserToShare(id: string, body?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).inviteUserToShare(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaveShare(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).leaveShare(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromShare(id: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).removeUserFromShare(id, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startShareContainer(slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ShareApiAxiosParamCreator(configuration).startShareContainer(slug, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShareApi - factory interface
 * @export
 */
export const ShareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProtectedUser(id: string, body?: InlineObject1, options?: any): AxiosPromise<object> {
            return ShareApiFp(configuration).addProtectedUser(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShareCreation} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShare(body: ShareCreation, options?: any): AxiosPromise<Share> {
            return ShareApiFp(configuration).createShare(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShare(id: string, options?: any): AxiosPromise<object> {
            return ShareApiFp(configuration).deleteShare(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [id2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShare(id: string, id2?: string, options?: any): AxiosPromise<Share> {
            return ShareApiFp(configuration).getShare(id, id2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareByContainerId(containerId: number, options?: any): AxiosPromise<Share> {
            return ShareApiFp(configuration).getShareByContainerId(containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareContainerState(slug: string, options?: any): AxiosPromise<ContainerState> {
            return ShareApiFp(configuration).getShareContainerState(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShares(options?: any): AxiosPromise<Array<Share>> {
            return ShareApiFp(configuration).getShares(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserToShare(id: string, body?: InlineObject2, options?: any): AxiosPromise<object> {
            return ShareApiFp(configuration).inviteUserToShare(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaveShare(id: string, options?: any): AxiosPromise<object> {
            return ShareApiFp(configuration).leaveShare(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromShare(id: string, email: string, options?: any): AxiosPromise<object> {
            return ShareApiFp(configuration).removeUserFromShare(id, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startShareContainer(slug: string, options?: any): AxiosPromise<object> {
            return ShareApiFp(configuration).startShareContainer(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShareApi - object-oriented interface
 * @export
 * @class ShareApi
 * @extends {BaseAPI}
 */
export class ShareApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {InlineObject1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public addProtectedUser(id: string, body?: InlineObject1, options?: any) {
        return ShareApiFp(this.configuration).addProtectedUser(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShareCreation} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public createShare(body: ShareCreation, options?: any) {
        return ShareApiFp(this.configuration).createShare(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public deleteShare(id: string, options?: any) {
        return ShareApiFp(this.configuration).deleteShare(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [id2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getShare(id: string, id2?: string, options?: any) {
        return ShareApiFp(this.configuration).getShare(id, id2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getShareByContainerId(containerId: number, options?: any) {
        return ShareApiFp(this.configuration).getShareByContainerId(containerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getShareContainerState(slug: string, options?: any) {
        return ShareApiFp(this.configuration).getShareContainerState(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getShares(options?: any) {
        return ShareApiFp(this.configuration).getShares(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {InlineObject2} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public inviteUserToShare(id: string, body?: InlineObject2, options?: any) {
        return ShareApiFp(this.configuration).inviteUserToShare(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public leaveShare(id: string, options?: any) {
        return ShareApiFp(this.configuration).leaveShare(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public removeUserFromShare(id: string, email: string, options?: any) {
        return ShareApiFp(this.configuration).removeUserFromShare(id, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public startShareContainer(slug: string, options?: any) {
        return ShareApiFp(this.configuration).startShareContainer(slug, options).then((request) => request(this.axios, this.basePath));
    }

}


